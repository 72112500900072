<template>
    <div class="table-view notifications-table-view">
        <div class="action-header">
            <h1>{{ $t('Notifications') }}</h1>
            <div class="actions">
                <button type="button" class="btn btn-grey" @click="getNotifications">
                    {{ $t('Refresh') }}
                    <svg xmlns="http://www.w3.org/2000/svg" width="20.948" height="20" viewBox="0 0 20.948 20">
                        <g>
                            <g>
                                <path d="M10 13a8.37 8.37 0 0 1 7.528 4.47 3 3 0 0 1-2.58 4.53h-9.9a3 3 0 0 1-2.581-4.53A8.37 8.37 0 0 1 10 13zm0 2a6.383 6.383 0 0 0-5.808 3.49 1 1 0 0 0 .86 1.51h9.9a1 1 0 0 0 .86-1.51A6.383 6.383 0 0 0 10 15zm9-7a1 1 0 0 1 .993.883L20 9v2h2a1 1 0 0 1 .117 1.993L22 13h-2v2a1 1 0 0 1-1.993.117L18 15v-2h-2a1 1 0 0 1-.117-1.993L16 11h2V9a1 1 0 0 1 1-1zm-8.833-6a5 5 0 0 1 0 10H10a5 5 0 0 1 0-10h.167zm-.083 2h.083a3 3 0 0 0-.083 6H10a3 3 0 0 0 .083-6z" transform="translate(-820.052 268) translate(818 -270)"/>
                            </g>
                        </g>
                    </svg>
                </button>
            </div>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">{{ $t("Title") }}</th>
                    <th scope="col">{{ $t("Status") }}</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="notification in notifications" :key="notification.id">
                    <td>{{ notification.title }}</td>
                    <td>
                        <Multiselect
                            v-model="notification.status"
                            :options="statuses"
                            @select="updateStatus(notification)"
                            :placeholder="$t('Select status')"
                            class="form-control"
                        />
                    </td>
                    <td class="text-end">
                        <router-link :to="'/settings/notifications?id=' + notification.id" class="me-2">
                            <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="15.006" height="15.013" viewBox="0 0 15.006 15.013">
                                <g>
                                    <g>
                                        <path d="M21.434 11.168l3.581 3.567-8.655 8.655-3.567-3.567zM11.7 24.027a.4.4 0 0 0 .117.344.4.4 0 0 0 .344.117l3.006-.344-3.124-3.134zm14.55-12.673l-1.431-1.431a1.507 1.507 0 0 0-2.136 0l-.272.272 3.577 3.567.272-.272a1.507 1.507 0 0 0 0-2.136z" transform="translate(-11.698 -9.479) translate(11.698 9.479) translate(-11.698 -9.479)"/>
                                    </g>
                                </g>
                            </svg>
                        </router-link>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import HeaderSearch from '@/mixins/HeaderSearch'
import {notificationService} from '@/services/settings/notificationService'
import Multiselect from '@vueform/multiselect'

export default {
    name: "ViewAllNotifications",
    mixins: [ HeaderSearch ],
    components: {
        Multiselect
    },
    data() {
        return {
            notifications: [],
            search: '',
            page: '',
            showAddComponent: false,
        }
    },
    mounted() {
        this.getNotifications()
    },
    methods: {
        searchFromHeader(keyword) {
            this.search = keyword;
            this.getNotifications();
        },
        getNotifications() {
            notificationService.index({
                "q": this.search,
                "page": this.page
            }).then(response => {
                this.notifications = response.data.data
            })
        },
        updateStatus(notification) {
            notificationService.status(notification.id, {
                "status": notification.status
            }).then(response => {
                this.getNotifications()
            })
        }
    },
    computed: {
        statuses() {
            return [
                {
                    label: this.$t('Disabled'),
                    value: 0
                },
                {
                    label: this.$t('Active'),
                    value: 1
                }
            ]
        }
    }
}
</script>